<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
           <template v-slot:body>
                <b-overlay :show="loading">
                    <template>
                        <div style="font-size:18px; background-color: var(--brand-color);">
                        <h5 class="text-white text-center">
                            {{ $t('admission_form.gen_inf') }}
                        </h5>
                        </div>
                    </template>
                    <b-table-simple bordered>
                            <b-tr>
                                <b-th>{{ $t('educational_management.name') }}</b-th>
                                <b-td>{{ ($i18n.locale=='bn') ? generalInfo.name_bn : generalInfo.name }}</b-td>
                                <b-th>{{ $t('externalUserIrrigation.father_name') }}</b-th>
                                <b-td>{{ ($i18n.locale=='bn') ? generalInfo.father_name_bn : generalInfo.father_name }}</b-td>
                                <b-th>{{ $t('externalUserIrrigation.mother_name') }}</b-th>
                                <b-td>{{ ($i18n.locale=='bn') ? generalInfo.mother_name_bn : generalInfo.mother_name }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>{{ $t('externalUserIrrigation.date_of_birth') }}</b-th>
                                <b-td>{{ generalInfo.date_of_birth | dateFormat }}</b-td>
                                <b-th>{{ $t('common_config.farmer_gender') }}</b-th>
                                <b-td>{{ ($i18n.locale=='bn') ? generalInfo.gender_name_bn : generalInfo.gender_name }}</b-td>
                                <b-th>{{ $t('common_config.farmer_blood_group') }}</b-th>
                                <b-td>{{ ($i18n.locale=='bn') ? generalInfo.blood_name_bn : generalInfo.blood_name }}</b-td>
                            </b-tr>
                            <b-tr>
                            <b-th>{{ $t('common_config.farmer_education_lebel') }}</b-th>
                                <b-td>{{ ($i18n.locale=='bn') ? generalInfo.education_label_bn : generalInfo.education_label }}</b-td>
                                <b-th>{{ $t('admission_form.mobile_no') }}</b-th>
                                <b-td>{{ ($i18n.locale == 'bn' ? '০': '0') + $n(generalInfo.mobile_no, { useGrouping: false }) }}</b-td>
                                <b-th>{{ $t('admission_form.nid_no') }}</b-th>
                                <b-td>{{ $n(generalInfo.nid_no, { useGrouping: false }) }}</b-td>
                            </b-tr>
                    </b-table-simple>
                    <template>
                        <div style="font-size:18px; background-color: var(--brand-color);">
                        <h5 class="text-white text-center">
                            {{ $t('common_config.other_info') }}
                        </h5>
                        </div>
                    </template>
                    <b-table-simple bordered>
                            <b-tr>
                                <b-th>{{ $t('common_config.card_number') }}</b-th>
                                <b-td>{{ $n(otherInfo.card_number, { useGrouping: false }) }}</b-td>
                                <b-th>{{ $t('common_config.family_number') }}</b-th>
                                <b-td>{{ $n(otherInfo.family_number, { useGrouping: false }) }}</b-td>
                                <b-th>{{ $t('common_config.association_name') }}</b-th>
                                <b-td>{{ ($i18n.locale=='bn') ? otherInfo.association_name_bn : otherInfo.association_name }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>{{ $t('common_config.income_source') }}</b-th>
                                <b-td>{{ ($i18n.locale=='bn') ? otherInfo.income_source_bn : otherInfo.income_source }}</b-td>
                            </b-tr>
                    </b-table-simple>
                    <template>
                        <div style="font-size:18px; background-color: var(--brand-color);">
                        <h5 class="text-white text-center">
                            {{ $t('common_config.land_info') }}
                        </h5>
                        </div>
                    </template>
                    <b-table-simple bordered>
                            <b-tr>
                                <b-th>{{ $t('common_config.own_land') }}</b-th>
                                <b-td>{{ $n(landInfo.own_land, { useGrouping: false }) }}</b-td>
                                <b-th>{{ $t('common_config.lease_land') }}</b-th>
                                <b-td>{{ $n(landInfo.lease_land, { useGrouping: false }) }}</b-td>
                                <b-th>{{ $t('common_config.barga_land') }}</b-th>
                                <b-td>{{ $n(landInfo.barga_land, { useGrouping: false }) }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>{{ $t('common_config.total_land') }}</b-th>
                                <b-td>{{ $n(landInfo.total_land, { useGrouping: false }) }}</b-td>
                            </b-tr>
                    </b-table-simple>
                    <template>
                        <div style="font-size:18px; background-color: var(--brand-color);">
                            <h5 class="text-white text-center">
                                {{ $t('common_config.crop_info') }}
                            </h5>
                        </div>
                    </template>
                    <b-table-simple bordered>
                        <b-tr class="text-center">
                            <b-th>{{ $t('common_config.crop_name') }}</b-th>
                        </b-tr>
                        <b-tr v-for="(cropData, index) in cropInfo" :key="index" class="text-center">
                            <b-td>{{ ($i18n.locale=='bn') ? cropData.crop_name_bn : cropData.crop_name }}</b-td>
                            <!-- <b-td>{{ ($i18n.locale=='bn') ? cropData.crop_id : cropData.crop_id }}</b-td> -->
                        </b-tr>
                    </b-table-simple>
                    <template>
                        <div style="font-size:18px; background-color: var(--brand-color);">
                        <h5 class="text-white text-center">
                            {{ $t('admission_form.add_info') }}
                        </h5>
                        </div>
                    </template>
                    <b-table-simple bordered>
                        <b-tr>
                            <b-th colspan="6" class="text-center">{{ $t('educational_management.present_business_address') }}</b-th>
                        </b-tr>
                        <b-tr>
                            <b-th>{{ $t('org_pro.area_type') }}</b-th>
                            <b-td>{{ ($i18n.locale=='bn') ? addressInfo.area_name_bn : addressInfo.area_name }}</b-td>
                            <b-th>{{ $t('org_pro_division.division') }}</b-th>
                            <b-td>{{ ($i18n.locale=='bn') ? addressInfo.div_name_bn : addressInfo.div_name }}</b-td>
                            <b-th>{{ $t('org_pro_district.district') }}</b-th>
                            <b-td>{{ ($i18n.locale=='bn') ? addressInfo.dis_name_bn : addressInfo.dis_name }}</b-td>
                        </b-tr>
                        <b-tr>
                            <b-th>{{ $t('org_pro_upazilla.upazilla') }}</b-th>
                            <b-td>{{ ($i18n.locale=='bn') ? addressInfo.upa_name_bn : addressInfo.upa_name }}</b-td>
                            <b-th>{{ $t('org_pro_union.union') }}</b-th>
                            <b-td>{{ ($i18n.locale=='bn') ? addressInfo.union_name_bn : addressInfo.union_name }}</b-td>
                            <b-th>{{ $t('org_pro.city_corporation') }}</b-th>
                            <b-td>{{ ($i18n.locale=='bn') ? addressInfo.cityCor_name_bn : addressInfo.cityCor_name }}</b-td>
                        </b-tr>
                        <b-tr>
                            <b-th>{{ $t('org_pro.pauroshoba') }}</b-th>
                            <b-td>{{ ($i18n.locale=='bn') ? addressInfo.pauro_name_bn : addressInfo.pauro_name }}</b-td>
                            <b-th>{{ $t('org_pro.ward') }}</b-th>
                            <b-td>{{ ($i18n.locale=='bn') ? addressInfo.ward_name_bn : addressInfo.ward_name }}</b-td>
                            <b-th>{{ $t('globalTrans.village') }}</b-th>
                            <b-td>{{ ($i18n.locale=='bn') ? addressInfo.village_bn : addressInfo.village_en }}</b-td>
                        </b-tr>
                        <b-tr>
                            <b-th>{{ $t('common_config.block') }}</b-th>
                            <b-td>{{ ($i18n.locale=='bn') ? addressInfo.block_bn : addressInfo.block_en }}</b-td>
                        </b-tr>
                    </b-table-simple>
                    <b-table-simple bordered>
                            <b-tr>
                                <b-th colspan="6" class="text-center">{{ $t('educational_management.permanent_address') }}</b-th>
                            </b-tr>
                            <b-tr>
                                <b-th>{{ $t('org_pro.area_type') }}</b-th>
                                <b-td>{{ ($i18n.locale=='bn') ? addressInfo.per_area_name_bn : addressInfo.per_area_name }}</b-td>
                                <b-th>{{ $t('org_pro_division.division') }}</b-th>
                                <b-td>{{ ($i18n.locale=='bn') ? addressInfo.per_div_name_bn : addressInfo.per_div_name }}</b-td>
                                <b-th>{{ $t('org_pro_district.district') }}</b-th>
                                <b-td>{{ ($i18n.locale=='bn') ? addressInfo.per_dis_name_bn : addressInfo.per_dis_name }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>{{ $t('org_pro_upazilla.upazilla') }}</b-th>
                                <b-td>{{ ($i18n.locale=='bn') ? addressInfo.per_upa_name_bn : addressInfo.per_upa_name }}</b-td>
                                <b-th>{{ $t('org_pro_union.union') }}</b-th>
                                <b-td>{{ ($i18n.locale=='bn') ? addressInfo.per_union_name_bn : addressInfo.per_union_name }}</b-td>
                                <b-th>{{ $t('org_pro.city_corporation') }}</b-th>
                                <b-td>{{ ($i18n.locale=='bn') ? addressInfo.per_cityCor_name_bn : addressInfo.per_cityCor_name }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>{{ $t('org_pro.pauroshoba') }}</b-th>
                                <b-td>{{ ($i18n.locale=='bn') ? addressInfo.per_pauro_name_bn : addressInfo.per_pauro_name }}</b-td>
                                <b-th>{{ $t('org_pro.ward') }}</b-th>
                                <b-td>{{ ($i18n.locale=='bn') ? addressInfo.per_ward_name_bn : addressInfo.per_ward_name }}</b-td>
                                <b-th>{{ $t('globalTrans.village') }}</b-th>
                                <b-td>{{ ($i18n.locale=='bn') ? addressInfo.per_village_bn : addressInfo.per_village_en }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>{{ $t('common_config.block') }}</b-th>
                                <b-td>{{ ($i18n.locale=='bn') ? addressInfo.per_block_bn : addressInfo.per_block_en }}</b-td>
                            </b-tr>
                    </b-table-simple>
                    <template v-if="listData.org_id === 8">
                        <div style="font-size:18px; background-color: var(--brand-color);">
                        <h5 class="text-white text-center">
                            {{ $t('bsri_config.mill_information') }}
                        </h5>
                        </div>
                    </template>
                    <b-table-simple bordered v-if="listData.org_id === '8'">
                            <b-tr>
                                <b-th>{{ $t('bsri_config.mill_name') }}</b-th>
                                <b-td>{{ ($i18n.locale=='bn') ? listData.mill_info_bn : listData.mill_info }}</b-td>
                                <b-th>{{ $t('bsri_config.subzone_office_name') }}</b-th>
                                <b-td>{{ ($i18n.locale=='bn') ? listData.subzone_office_name_bn : listData.subzone_office_name }}</b-td>
                                <b-th>{{ $t('bsri_config.center_name') }}</b-th>
                                <b-td>{{ ($i18n.locale=='bn') ? listData.center_bn : listData.center }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>{{ $t('cotton_config.unit_name') }}</b-th>
                                <b-td>{{ ($i18n.locale=='bn') ? listData.unit_bn : listData.unit }}</b-td>
                                <b-th></b-th>
                                <b-td></b-td>
                                <b-th></b-th>
                                <b-td></b-td>
                            </b-tr>
                    </b-table-simple>
                    <template>
                        <div style="font-size:18px; background-color: var(--brand-color);">
                        <h5 class="text-white text-center">
                            {{ $t('common_config.account_info') }}
                        </h5>
                        </div>
                    </template>
                    <b-table-simple bordered class="mt-1">
                        <b-tr>
                            <b-th>{{ $t('common_config.account_info') }}</b-th>
                            <b-td>{{ ($i18n.locale=='bn') ? generalInfo.account_name_bn : generalInfo.account_name }}</b-td>
                            <b-th>{{ $t('common_config.far_wallet_no') }}</b-th>
                            <b-td>{{  $n(generalInfo.wallet_no, { useGrouping: false }) }}</b-td>
                            <b-th>{{ $t('common_config.account_type') }}</b-th>
                            <b-td>{{ ($i18n.locale=='bn') ? generalInfo.ac_type_name_bn : generalInfo.ac_type_name }}</b-td>
                        </b-tr>
                    </b-table-simple>
                    <b-table-simple bordered>
                        <b-tr>
                            <b-th>{{ $t('common_config.account_no') }}</b-th>
                            <b-td>{{ $n(generalInfo.account_no, { useGrouping: false }) }}</b-td>
                            <b-th>{{ $t('common_config.routing_no') }}</b-th>
                            <b-td>{{ $n(generalInfo.routing_no, { useGrouping: false }) }}</b-td>
                            <b-th>{{ $t('bsri_demandDIS.bank') }}</b-th>
                            <b-td>{{ ($i18n.locale=='bn') ? generalInfo.bank_name_bn : generalInfo.bank_name }}</b-td>
                            <b-th>{{ $t('bsri_demandDIS.branch') }}</b-th>
                            <b-td>{{ ($i18n.locale=='bn') ? generalInfo.branch_name_bn : generalInfo.branch_name }}</b-td>
                        </b-tr>
                    </b-table-simple>
                </b-overlay>
           </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { farmerAllDetailsData } from '../../api/routes'
import flatpickr from 'flatpickr'
import ExportPdf from './export-pdf_details'

export default {
    props: ['id'],
    components: {
    },
    created () {
        if (this.id) {
            this.getFarmerApplicationDetails()
            this.listData = this.getEditingData()
        }
    },
    data () {
        return {
            loader: true,
            loading: false,
            generalInfo: [],
            otherInfo: [],
            landInfo: [],
            cropInfo: [],
            addressInfo: [],
            slOffset: 1,
            genderList: [
                { value: 1, text_en: 'Male', text_bn: 'পুরুষ' },
                { value: 2, text_en: 'Female', text_bn: 'মহিলা' },
                { value: 3, text_en: 'Other', text_bn: 'অন্যান্য' }
            ],
            bloodGroupList: [
                { value: 1, text_en: 'O-', text_bn: 'ও−' },
                { value: 2, text_en: 'O+', text_bn: 'ও+' },
                { value: 3, text_en: 'A-', text_bn: 'এ−' },
                { value: 4, text_en: 'A+', text_bn: 'এ+' },
                { value: 5, text_en: 'B-', text_bn: 'বি−' },
                { value: 6, text_en: 'B+', text_bn: 'বি+' },
                { value: 7, text_en: 'AB-', text_bn: 'এবি−' },
                { value: 8, text_en: 'AB+', text_bn: 'এবি+' }
            ],
            getAreaType: [
            { value: 1, text_en: 'City Corpoation', text_bn: 'সিটি কর্পোরেশন' },
            { value: 2, text_en: 'Pauroshoba', text_bn: 'পৌরসভা' },
            { value: 3, text_en: 'Union', text_bn: 'ইউনিয়ন' }
           ],
            accountTypeList: [
              { value: 1, text_en: 'Mobile Banking', text_bn: 'মোবাইল ব্যাংকিং' },
              { value: 2, text_en: 'Bank', text_bn: 'ব্যাংক' }
            ],
           listData: {
           }
        }
    },
    mounted () {
        core.index()
        flatpickr('#registration_date', {})
    },
    computed: {
    },
    methods: {
        async getFarmerApplicationDetails () {
            this.loading = true
            const result = await RestApi.getData(incentiveGrantServiceBaseUrl, `${farmerAllDetailsData}/${this.$props.id}`)
            if (result.success) {
                this.generalInfo = this.getRelationalData(result.data)
                this.otherInfo = result.data.other_info
                this.landInfo = result.data.land_info
                this.cropInfo = this.getRelationalCropDatta(result.data.crop_info)
                this.addressInfo = this.getRelationalAddressData(result.data.address_info)
            }
            this.loading = false
        },
        getEditingData () {
            const tmpData = this.$store.state.list.find(item => item.id === this.id)
            return JSON.parse(JSON.stringify(tmpData))
        },
        getRelationalData (data) {
            const genderType = this.genderList.find(gender => gender.value === parseInt(data.gender_id))
            const bloodType = this.bloodGroupList.find(blood => blood.value === parseInt(data.blood_group_id))
            const educationType = this.$store.state.incentiveGrant.commonObj.educationLevelList.find(education => education.value === data.education_level_id)
            const actypeList = this.$store.state.commonObj.bankObj.bankList.find(bank => bank.value === data.ac_type_id)
            const bankList = this.$store.state.commonObj.bankObj.bankList.find(bank => bank.value === data.bank_id)
            const branchList = this.$store.state.commonObj.bankObj.branchList.find(bank => bank.value === data.branch_id)
            const accountType = this.accountTypeList.find(type => type.value === parseInt(data.ac_category_id))
            return Object.assign({}, data,
              {
                gender_name: genderType !== undefined ? genderType.text_en : '',
                gender_name_bn: genderType !== undefined ? genderType.text_bn : '',
                blood_name: bloodType !== undefined ? bloodType.text_en : '',
                blood_name_bn: bloodType !== undefined ? bloodType.text_bn : '',
                education_label: educationType !== undefined ? educationType.text_en : '',
                education_label_bn: educationType !== undefined ? educationType.text_bn : '',
                account_name: accountType !== undefined ? accountType.text_en : '',
                account_name_bn: accountType !== undefined ? accountType.text_bn : '',
                ac_type_name: actypeList !== undefined ? actypeList.text_en : '',
                ac_type_name_bn: actypeList !== undefined ? actypeList.text_bn : '',
                bank_name: bankList !== undefined ? bankList.text_en : '',
                bank_name_bn: bankList !== undefined ? bankList.text_bn : '',
                branch_name: branchList !== undefined ? branchList.text_en : '',
                branch_name_bn: branchList !== undefined ? branchList.text_bn : ''
              }
            )
        },
        getRelationalCropDatta (data) {
        const cropList = this.$store.state.incentiveGrant.commonObj.cropList.filter(item => item.status === 1)
        const listData = data.map(item => {
            const cropTypeObj = cropList.find(crop => crop.value === item.crop_id)
            const cropTypeData = { crop_name: cropTypeObj !== undefined ? cropTypeObj.text_en : '', crop_name_bn: cropTypeObj !== undefined ? cropTypeObj.text_bn : '' }
            return Object.assign({}, item, cropTypeData)
        })
        return listData
        },
        getRelationalAddressData (data) {
            const areaList = this.getAreaType.find(aType => aType.value === parseInt(data.area_type_id))
            const divList = this.$store.state.commonObj.divisionList.find(div => div.value === data.division_id)
            const disList = this.$store.state.commonObj.districtList.find(dis => dis.value === data.district_id)
            const upaList = this.$store.state.commonObj.upazilaList.find(upa => upa.value === data.upazilla_id)
            const unionList = this.$store.state.commonObj.unionList.find(union => union.value === data.union_id)
            const cityCorList = this.$store.state.commonObj.cityCorporationList.find(city => city.value === data.city_corporation_id)
            const pauroList = this.$store.state.commonObj.pauroshobaList.find(pauro => pauro.value === data.pauroshoba_id)
            const wardList = this.$store.state.commonObj.wardList.find(ward => ward.value === data.ward_id)

            const areaListPer = this.getAreaType.find(aType => aType.value === parseInt(data.area_type_id))
            const divListPer = this.$store.state.commonObj.divisionList.find(div => div.value === data.per_division_id)
            const disListPer = this.$store.state.commonObj.districtList.find(dis => dis.value === data.per_district_id)
            const upaListPer = this.$store.state.commonObj.upazilaList.find(upa => upa.value === data.per_upazilla_id)
            const unionListPer = this.$store.state.commonObj.unionList.find(union => union.value === data.per_union_id)
            const cityCorListPer = this.$store.state.commonObj.cityCorporationList.find(city => city.value === data.per_city_corporation_id)
            const pauroListPer = this.$store.state.commonObj.pauroshobaList.find(pauro => pauro.value === data.per_pauroshoba_id)
            const wardListPer = this.$store.state.commonObj.wardList.find(ward => ward.value === data.per_ward_id)
            return Object.assign({}, data,
                { area_name: areaList !== undefined ? areaList.text_en : '', area_name_bn: areaList !== undefined ? areaList.text_bn : '' },
                { div_name: divList !== undefined ? divList.text_en : '', div_name_bn: divList !== undefined ? divList.text_bn : '' },
                { dis_name: disList !== undefined ? disList.text_en : '', dis_name_bn: disList !== undefined ? disList.text_bn : '' },
                { upa_name: upaList !== undefined ? upaList.text_en : '', upa_name_bn: upaList !== undefined ? upaList.text_bn : '' },
                { union_name: unionList !== undefined ? unionList.text_en : '', union_name_bn: unionList !== undefined ? unionList.text_bn : '' },
                { cityCor_name: cityCorList !== undefined ? cityCorList.text_en : '', cityCor_name_bn: cityCorList !== undefined ? cityCorList.text_bn : '' },
                { pauro_name: pauroList !== undefined ? pauroList.text_en : '', pauro_name_bn: pauroList !== undefined ? pauroList.text_bn : '' },
                { ward_name: wardList !== undefined ? wardList.text_en : '', ward_name_bn: wardList !== undefined ? wardList.text_bn : '' },
                { per_area_name: areaListPer !== undefined ? areaListPer.text_en : '', per_area_name_bn: areaListPer !== undefined ? areaListPer.text_bn : '' },
                { per_div_name: divListPer !== undefined ? divListPer.text_en : '', per_div_name_bn: divListPer !== undefined ? divListPer.text_bn : '' },
                { per_dis_name: disListPer !== undefined ? disListPer.text_en : '', per_dis_name_bn: disListPer !== undefined ? disListPer.text_bn : '' },
                { per_upa_name: upaListPer !== undefined ? upaListPer.text_en : '', per_upa_name_bn: upaListPer !== undefined ? upaListPer.text_bn : '' },
                { per_union_name: unionListPer !== undefined ? unionListPer.text_en : '', per_union_name_bn: unionListPer !== undefined ? unionListPer.text_bn : '' },
                { per_cityCor_name: cityCorListPer !== undefined ? cityCorListPer.text_en : '', per_cityCor_name_bn: cityCorListPer !== undefined ? cityCorListPer.text_bn : '' },
                { per_pauro_name: pauroListPer !== undefined ? pauroListPer.text_en : '', per_pauro_name_bn: pauroListPer !== undefined ? pauroListPer.text_bn : '' },
                { per_ward_name: wardListPer !== undefined ? wardListPer.text_en : '', per_ward_name_bn: wardListPer !== undefined ? wardListPer.text_bn : '' }
            )
        },
        pdfExport () {
            const reportTitle = this.$i18n.locale === 'en' ? 'Farmer Information Report' : 'কৃষক তথ্য প্রতিবেদন'
            ExportPdf.exportPdfDetails(reportTitle, this.generalInfo, this, this.otherInfo, this.landInfo, this.cropInfo, this.addressInfo, this.listData)
        }
    }
}
</script>
