import i18n from '@/i18n'
import Store from '@/store'
// import ReportHeading from '@/Utils/report-head'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
import { dateFormat } from '@/Utils/fliter'

const exportPdfDetails = async (reportTitle, data, vm, otherInfo, landInfo, cropInfo, addressInfo, listData) => {
    try {
        Store.commit('mutateCommonProperties', {
            loading: true
        })
        if (i18n.locale === 'bn') {
            pdfMake.vfs = pdfFontsBn.pdfMake.vfs
        } else {
            pdfMake.vfs = pdfFontsEn.pdfMake.vfs
        }
        // const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
        const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
        const pdfContent = [{
                // columns: reportHeadData.reportHeadColumn
            },
            {
                columns: [
                    { width: '*', text: '' },
                    { width: '*', text: '' },
                    { width: '*', text: '' },
                    { width: '*', text: '' },
                    { width: '*', text: '' }
                ],
                style: 'krishi'
            },
            { text: vm.$t('common_config.farmer_information'), style: 'hh', alignment: 'center' }
        ]

        // if (reportHeadData.projectName) {
            // pdfContent.push({ text: reportHeadData.projectName, style: 'header3', alignment: 'center' })
        // }

        pdfContent.push({ text: vm.$t('admission_form.gen_inf'), style: 'header2', alignment: 'center', decoration: 'underline' })
        pdfContent.push({
            table: {
                style: 'tableData',
                headerRows: 0,
                widths: ['*', '*', '*', '*', '*', '*'],
                body: [
                    [
                        { text: vm.$t('educational_management.name'), style: 'th' },
                        { text: vm.$i18n.locale === 'en' ? data.name : data.name_bn, style: 'td' },
                        { text: vm.$t('externalUserIrrigation.father_name'), style: 'th' },
                        { text: (i18n.locale === 'en') ? data.father_name : data.father_name_bn, style: 'td' },
                        { text: vm.$t('externalUserIrrigation.mother_name'), style: 'th' },
                        { text: (i18n.locale === 'en') ? data.mother_name : data.mother_name_bn, style: 'td' }
                    ],
                    [
                        { text: vm.$t('externalUserIrrigation.date_of_birth'), style: 'th' },
                        { text: dateFormat(data.date_of_birth), style: 'td' },
                        { text: vm.$t('common_config.farmer_gender'), style: 'th' },
                        { text: (i18n.locale === 'en') ? data.gender_name : data.gender_name_bn, style: 'td' },
                        { text: vm.$t('common_config.farmer_blood_group'), style: 'th' },
                        { text: (i18n.locale === 'en') ? data.blood_name : data.blood_name_bn, style: 'td' }
                    ],
                    [
                        { text: vm.$t('common_config.farmer_education_lebel'), style: 'th' },
                        { text: vm.$i18n.locale === 'en' ? data.education_label : data.education_label_bn, style: 'td' },
                        { text: vm.$t('admission_form.mobile_no'), style: 'th' },
                        { text: vm.$n(data.mobile_no), style: 'td' },
                        { text: vm.$t('admission_form.nid_no'), style: 'th' },
                        { text: vm.$n(data.nid), style: 'td' }
                    ]
                ]
            }
        })
        pdfContent.push({ text: vm.$t('common_config.other_info'), style: 'header2', alignment: 'center', decoration: 'underline' })
        pdfContent.push({
            table: {
                style: 'tableData',
                headerRows: 0,
                widths: ['*', '*', '*', '*', '*', '*'],
                body: [
                    [
                        { text: vm.$t('common_config.card_number'), style: 'th' },
                        { text: vm.$n(otherInfo.card_number), style: 'td' },
                        { text: vm.$t('common_config.family_number'), style: 'th' },
                        { text: vm.$n(otherInfo.family_number), style: 'td' },
                        { text: vm.$t('common_config.association_name'), style: 'th' },
                        { text: (i18n.locale === 'en') ? otherInfo.association_name : otherInfo.association_name_bn, style: 'td' }
                    ],
                    [
                        { text: vm.$t('common_config.income_source'), style: 'th' },
                        { text: (i18n.locale === 'en') ? otherInfo.income_source : otherInfo.income_source_bn, style: 'td' },
                        {},
                        {},
                        {},
                        {}
                    ]
                ]
            }
        })
        pdfContent.push({ text: vm.$t('common_config.land_info'), style: 'header2', alignment: 'center', decoration: 'underline' })
        pdfContent.push({
            table: {
                style: 'tableData',
                headerRows: 0,
                widths: ['*', '*', '*', '*', '*', '*'],
                body: [
                    [
                        { text: vm.$t('common_config.own_land'), style: 'th' },
                        { text: vm.$n(landInfo.own_land), style: 'td' },
                        { text: vm.$t('common_config.lease_land'), style: 'th' },
                        { text: vm.$n(landInfo.lease_land), style: 'td' },
                        { text: vm.$t('common_config.barga_land'), style: 'th' },
                        { text: vm.$n(landInfo.barga_land), style: 'td' }
                    ],
                    [
                        { text: vm.$t('common_config.total_land'), style: 'th' },
                        { text: vm.$n(landInfo.total_land), style: 'td' },
                        {},
                        {},
                        {},
                        {}
                    ]
                ]
            }
        })
        pdfContent.push({ text: vm.$t('common_config.crop_info'), style: 'header2', alignment: 'center', decoration: 'underline' })
        var allRowTo = []
        var tabledataTo = [
            // { text: vm.$t('globalTrans.sl_no'), style: 'td', alignment: 'center' },
            { text: vm.$t('common_config.crop_name'), style: 'th', alignment: 'center' }
        ]
        allRowTo.push(tabledataTo)
        cropInfo.map(item => {
            // serial += 1
            tabledataTo = [
                // { text: vm.$n(serial), style: 'td', alignment: 'center' },
                { text: vm.$i18n.locale === 'bn' ? item.crop_name_bn : item.crop_name, style: 'td', alignment: 'center' }
            ]
            allRowTo.push(tabledataTo)
        })
        pdfContent.push({
            table: {
                headerRows: 0,
                style: 'header2',
                widths: ['*'],
                body: allRowTo
            }
        })
        pdfContent.push({ text: '', style: 'header2', alignment: 'center', decoration: 'underline' })
        pdfContent.push({ text: '', style: 'header2', alignment: 'center', decoration: 'underline' })
        pdfContent.push({ text: vm.$t('admission_form.add_info'), style: 'header2', alignment: 'center', decoration: 'underline' })
        pdfContent.push({ text: vm.$t('educational_management.present_business_address'), style: 'header11', alignment: 'center', decoration: 'underline' })
        pdfContent.push({
            table: {
                style: 'tableData',
                headerRows: 0,
                widths: ['*', '*', '*', '*', '*', '*'],
                body: [
                    [
                        { text: vm.$t('org_pro.area_type'), style: 'th' },
                        { text: vm.$i18n.locale === 'en' ? addressInfo.area_name : addressInfo.area_name_bn, style: 'td' },
                        { text: vm.$t('org_pro_division.division'), style: 'th' },
                        { text: (i18n.locale === 'en') ? addressInfo.div_name : addressInfo.div_name_bn, style: 'td' },
                        { text: vm.$t('org_pro_district.district'), style: 'th' },
                        { text: (i18n.locale === 'en') ? addressInfo.dis_name : addressInfo.dis_name_bn, style: 'td' }
                    ],
                    [
                        { text: vm.$t('org_pro_upazilla.upazilla'), style: 'th' },
                        { text: vm.$i18n.locale === 'en' ? addressInfo.upa_name : addressInfo.upa_name_bn, style: 'td' },
                        { text: vm.$t('org_pro_union.union'), style: 'th' },
                        { text: (i18n.locale === 'en') ? addressInfo.union_name : addressInfo.union_name_bn, style: 'td' },
                        { text: vm.$t('org_pro.city_corporation'), style: 'th' },
                        { text: (i18n.locale === 'en') ? addressInfo.cityCor_name : addressInfo.cityCor_name_bn, style: 'td' }
                    ],
                    [
                        { text: vm.$t('org_pro.pauroshoba'), style: 'th' },
                        { text: vm.$i18n.locale === 'en' ? addressInfo.pauro_name : addressInfo.pauro_name_bn, style: 'td' },
                        { text: vm.$t('org_pro.ward'), style: 'th' },
                        { text: vm.$i18n.locale === 'en' ? addressInfo.ward_name : addressInfo.ward_name_bn, style: 'td' },
                        { text: vm.$t('globalTrans.village'), style: 'th' },
                        { text: vm.$i18n.locale === 'en' ? addressInfo.village_name : addressInfo.village_name_bn, style: 'td' }
                    ],
                    [
                        { text: vm.$t('common_config.block'), style: 'th' },
                        { text: (i18n.locale === 'en') ? addressInfo.block_name_bn : addressInfo.block_name, style: 'td' },
                        {},
                        {},
                        {},
                        {}
                    ]
                ]
            }
        })
        pdfContent.push({ text: vm.$t('educational_management.permanent_address'), style: 'header11', alignment: 'center', decoration: 'underline' })
        pdfContent.push({
            table: {
                style: 'tableData',
                headerRows: 0,
                widths: ['*', '*', '*', '*', '*', '*'],
                body: [
                    [
                        { text: vm.$t('org_pro.area_type'), style: 'th' },
                        { text: vm.$i18n.locale === 'en' ? addressInfo.per_area_name : addressInfo.per_area_name_bn, style: 'td' },
                        { text: vm.$t('org_pro_division.division'), style: 'th' },
                        { text: (i18n.locale === 'en') ? addressInfo.per_div_name : addressInfo.per_div_name_bn, style: 'td' },
                        { text: vm.$t('org_pro_district.district'), style: 'th' },
                        { text: (i18n.locale === 'en') ? addressInfo.per_dis_name : addressInfo.per_dis_name_bn, style: 'td' }
                    ],
                    [
                        { text: vm.$t('org_pro_upazilla.upazilla'), style: 'th' },
                        { text: vm.$i18n.locale === 'en' ? addressInfo.per_upa_name : addressInfo.per_upa_name_bn, style: 'td' },
                        { text: vm.$t('org_pro_union.union'), style: 'th' },
                        { text: (i18n.locale === 'en') ? addressInfo.per_union_name : addressInfo.per_union_name_bn, style: 'td' },
                        { text: vm.$t('org_pro.city_corporation'), style: 'th' },
                        { text: (i18n.locale === 'en') ? addressInfo.per_cityCor_name : addressInfo.per_cityCor_name_bn, style: 'td' }
                    ],
                    [
                        { text: vm.$t('org_pro.pauroshoba'), style: 'th' },
                        { text: vm.$i18n.locale === 'en' ? addressInfo.per_pauro_name : addressInfo.per_pauro_name_bn, style: 'td' },
                        { text: vm.$t('org_pro.ward'), style: 'th' },
                        { text: vm.$i18n.locale === 'en' ? addressInfo.per_ward_name : addressInfo.per_ward_name_bn, style: 'td' },
                        { text: vm.$t('globalTrans.village'), style: 'th' },
                        { text: vm.$i18n.locale === 'en' ? addressInfo.per_village_name : addressInfo.per_village_name_bn, style: 'td' }
                    ],
                    [
                        { text: vm.$t('common_config.block'), style: 'th' },
                        { text: (i18n.locale === 'en') ? addressInfo.per_block_name : addressInfo.per_block_name_bn, style: 'td' },
                        {},
                        {},
                        {},
                        {}
                    ]
                ]
            }
        })
        pdfContent.push({ text: vm.$t('bsri_config.mill_information'), style: 'header2', alignment: 'center', decoration: 'underline' })
        pdfContent.push({
            table: {
                style: 'tableData',
                headerRows: 0,
                widths: ['*', '*', '*', '*', '*', '*'],
                body: [
                    [
                        { text: vm.$t('bsri_config.mill_name'), style: 'th' },
                        { text: (i18n.locale === 'en') ? listData.mill_info : listData.mill_info_bn, style: 'td' },
                        { text: vm.$t('bsri_config.subzone_office_name'), style: 'th' },
                        { text: (i18n.locale === 'en') ? listData.subzone_office_name : listData.subzone_office_name_bn, style: 'td' },
                        { text: vm.$t('bsri_config.center_name'), style: 'th' },
                        { text: (i18n.locale === 'en') ? listData.center : listData.center_bn, style: 'td' }
                    ],
                    [
                        { text: vm.$t('cotton_config.unit_name'), style: 'th' },
                        { text: (i18n.locale === 'en') ? listData.unit : listData.unit_bn, style: 'td' },
                        {},
                        {},
                        {},
                        {}
                    ]
                ]
            }
        })
        pdfContent.push({ text: vm.$t('common_config.account_info'), style: 'header2', alignment: 'center', decoration: 'underline' })
        pdfContent.push({
            table: {
                style: 'tableData',
                headerRows: 0,
                widths: ['*', '*', '*', '*', '*', '*'],
                body: [
                    [
                        { text: vm.$t('common_config.account_info'), style: 'th' },
                        { text: (i18n.locale === 'en') ? data.account_name : data.account_name_bn, style: 'td' },
                        { text: vm.$t('common_config.far_wallet_no'), style: 'th' },
                        { text: vm.$n(data.wallet_no), style: 'td' },
                        { text: vm.$t('common_config.account_type'), style: 'th' },
                        { text: (i18n.locale === 'en') ? data.ac_type_name : data.ac_type_name_bn, style: 'td' }
                    ]
                ]
            }
        })
        pdfContent.push({
            table: {
                style: 'tableData',
                headerRows: 0,
                widths: ['*', '*', '*', '*', '*', '*', '*', '*'],
                body: [
                    [
                        { text: vm.$t('common_config.account_no'), style: 'th' },
                        { text: vm.$n(data.account_no), style: 'td' },
                        { text: vm.$t('common_config.routing_no'), style: 'th' },
                        { text: vm.$n(data.routing_no, { useGrouping: false }), style: 'td' },
                        { text: vm.$t('bsri_demandDIS.bank'), style: 'th' },
                        { text: (i18n.locale === 'en') ? data.bank_name : data.bank_name_bn, style: 'td' },
                        { text: vm.$t('bsri_demandDIS.branch'), style: 'th' },
                        { text: (i18n.locale === 'en') ? data.branch_name : data.branch_name_bn, style: 'td' }
                    ]
                ]
            }
        })
        var docDefinition = {
            content: pdfContent,
            pageSize: 'A4',
            pageOrientation: 'landscape',
            watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
            styles: {
                th: {
                    fillColor: '',
                    fontSize: 10,
                    bold: true,
                    margin: [3, 3, 3, 3]
                },
                td: {
                    fontSize: 10,
                    margin: [3, 3, 3, 3]
                },
                header: {
                    fontSize: 12,
                    bold: true,
                    margin: [0, 0, 0, 4]
                },
                header2: {
                    fontSize: 15,
                    bold: true,
                    margin: [15, 15, 25, 10]
                },
                hh: {
                    fontSize: 17,
                    bold: true,
                    margin: [25, 15, 25, 10]
                },
                header11: {
                    fontSize: 13,
                    bold: true,
                    margin: [15, 10, 10, 10]
                },
                transport: {
                    fontSize: 10,
                    margin: [10, 10, 0, 20]
                },
                headerPort1: {
                    fontSize: 10,
                    margin: [0, 20, 0, 0]
                },
                headerPort: {
                    fontSize: 10,
                    margin: [0, 4, 0, 15]
                },
                header3: {
                    fontSize: 9,
                    margin: [0, 0, 0, 4]
                },
                tableSubHead: {
                    margin: [0, 5, 0, 15]
                },
                krishi: {
                    margin: [0, -15, 0, 15],
                    alignment: 'center'
                }
            }
        }
        pdfMake.createPdf(docDefinition, null, null, null).download('farmer-information')
    } catch (error) {
        if (error) {}
    }
    Store.commit('mutateCommonProperties', {
        loading: false
    })
}
export default {
    exportPdfDetails
}
