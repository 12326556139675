
<template>
    <b-container fluid>
        <iq-card>
            <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('common_config.farmer_information') }}</h4>
            </template>
            <template v-slot:body>
                <b-row align-h="around">
                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                      <b-form-group
                          class="row"
                          label-cols-sm="5"
                          :label="$t('globalTrans.organization')"
                          label-for="org_id"
                          >
                          <b-form-select
                              plain
                              v-model="search.org_id"
                              text="text"
                              :options="orgList"
                              id="org_id"
                              >
                              <template v-slot:first>
                              <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                          </b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                        <b-form-group
                            class="row"
                            label-cols-sm="5"
                            label-for="division_id"
                            >
                            <template v-slot:label>
                                {{ $t('org_pro_division.division')}}
                            </template>
                            <b-form-select
                            plain
                            v-model="search.division_id"
                            :options="divisionList"
                            id="division_id"
                            >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                        <b-form-group
                            class="row"
                            label-cols-sm="5"
                            label-for="district_id"
                            >
                            <template v-slot:label>
                                {{ $t('org_pro_district.district')}}
                            </template>
                            <b-form-select
                            plain
                            v-model="search.district_id"
                            :options="districtList"
                            id="district_id"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                        <b-form-group
                            class="row"
                            label-cols-sm="5"
                            label-for="upazila_id"
                            >
                            <template v-slot:label>
                            {{ $t('org_pro_upazilla.upazilla')}}
                            </template>
                            <b-form-select
                            plain
                            v-model="search.upazilla_id"
                            :options="upazilaList"
                            id="upazila_id"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                        <b-form-group
                            class="row"
                            label-cols-sm="5"
                            :label="$t('bsri_demandDIS.mill_name')"
                            label-for="mill_info_id"
                            >
                            <b-form-select
                                plain
                                v-model="search.mill_info_id"
                                text="text"
                                :options="millInfoList"
                                id="mill_info_id"
                                >
                                <template v-slot:first>
                                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                        <b-form-group
                            class="row"
                            label-cols-sm="5"
                            :label="$t('bsri_demandDIS.subzone_office_name')"
                            label-for="subzone_office_id"
                            >
                            <b-form-select
                                plain
                                v-model="search.subzone_office_id"
                                text="text"
                                :options="subzoneOfficeList"
                                id="subzone_office_id"
                                >
                                <template v-slot:first>
                                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                        <b-form-group
                            class="row"
                            label-cols-sm="5"
                            :label="$t('bsri_config.center_name')"
                            label-for="center_id"
                            >
                            <b-form-select
                                plain
                                v-model="search.center_id"
                                text="text"
                                :options="centerList"
                                id="center_id"
                                >
                                <template v-slot:first>
                                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                        <b-form-group
                            class="row"
                            label-cols-sm="5"
                            :label="$t('bsri_demandDIS.unit')"
                            label-for="unit_id"
                            >
                            <b-form-select
                                plain
                                v-model="search.unit_id"
                                text="text"
                                :options="unitList"
                                id="unit_id"
                                >
                                <template v-slot:first>
                                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                        <b-form-group
                            class="row"
                            label-cols-sm="5"
                            :label="$t('common_config.farmer_mobile_name')"
                            label-for="mobile_no"
                            >
                            <b-form-input
                                plain
                                v-model="search.mobile_no"
                                id="mobile_no"
                                >
                            </b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                      <b-form-group
                          class="row"
                          label-cols-sm="5"
                          :label="$t('common_config.farmer_nid_no')"
                          label-for="nid_no"
                          >
                          <b-form-input
                              plain
                              v-model="search.nid_no"
                              id="nid_no"
                              >
                          </b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col>
                        <b-button type="button" variant="primary float-right" @click="searchData">{{ $t('globalTrans.search') }}</b-button>
                    </b-col>
                </b-row>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{ $t('common_config.farmer_information_list') }}</h4>
                    </template>
                    <template v-slot:headerAction>
                         <b-button class="btn_add_new">
                            <router-link :to="{ path: '/incentive-grant-service/common-configuration/farmer-information-form'}" size="sm">
                                <i class="fas fa-plus"></i>{{  $t('globalTrans.add_new') }}
                            </router-link>
                        </b-button>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loader">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table thead-class="table_head" striped :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :items="listData" :fields="columns" aria-hidden="loader | listReload ? 'true' : null">
                                        <template v-slot:cell(index)="data">
                                            {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                        <template v-slot:cell(nid_no)="data">
                                            {{ $n(data.item.nid_no, { useGrouping: false }) }}
                                        </template>
                                        <template v-slot:cell(mobile_no)="data">
                                            {{ ($i18n.locale == 'bn' ? '০': '0') + $n(data.item.mobile_no, { useGrouping: false }) }}
                                        </template>
                                        <template v-slot:cell(status)="data">
                                            <span class="badge badge-success" v-if="data.item.status === 1">{{$t('globalTrans.active')}}</span>
                                            <span class="badge badge-danger" v-if="data.item.status === 2">{{$t('globalTrans.inactive')}}</span>
                                        </template>
                                        <template v-slot:cell(address)="data">
                                            {{ getAddress(data.item)}}
                                        </template>
                                        <template v-slot:cell(action)="data">
                                            <a href="javascript:" title="View" class="btn_table_action table_action_view" v-b-modal.modal-5 @click="details(data.item)"><i class="fas fa-eye"></i></a>
                                            <a href="javascript:" title="Status" class="btn_table_action" :class="data.item.status === 1 ? ' table_action_view' : ' table_action_toggle'" @click="remove(data.item)">
                                                <i class="fas" :class="data.item.status ? 'fa-toggle-on' : 'fa-toggle-off'"></i>
                                            </a>
                                            <router-link title="Edit" class="btn_table_action table_action_edit" :to="{ path: '/incentive-grant-service/common-configuration/farmer-information-form', query: { id: data.item.id }}">
                                                <i class="ri-ball-pen-fill m-0"></i>
                                            </router-link>
                                        </template>
                                    </b-table>
                                    <b-pagination
                                      align="center"
                                      v-model="pagination.currentPage"
                                      :per-page="pagination.perPage"
                                      :total-rows="pagination.totalRows"
                                      @input="searchData"
                                    />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-form" size="xl" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Form :id="editItemId" :key="editItemId"/>
        </b-modal>
         <b-modal id="modal-5" size="xl" :title="detailsTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <b-button @click="pdfExport" class="ml-4 btn-success water-test-pdf-button">
              {{  $t('globalTrans.export_pdf') }}
            </b-button>
            <FarmerDetails :id="editItemId" :key="editItemId"  ref="farmerdetails"/>
        </b-modal>
    </b-container>
</template>
<script>
import Form from './Form'
import FarmerDetails from './FarmerDetails'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { farmerListData, farmerInfoToggleStatus } from '../../api/routes'
import RouteBaseList from '@/mixins/route-base-list'
import flatpickr from 'flatpickr'

export default {
  mixins: [RouteBaseList],
  components: {
    Form, FarmerDetails
  },
  data () {
    return {
      search: {
        org_id: 0,
        division_id: 0,
        district_id: 0,
        upazilla_id: 0,
        mill_info_id: 0,
        subzone_office_id: 0,
        center_id: 0,
        unit_id: 0,
        mobile_no: '',
        nid_no: ''
      },
      editItemId: '',
      item: '',
      rows: [],
      loader: false,
      subzoneOfficeList: [],
      centerList: [],
      unitList: [],
      districtList: [],
      upazilaList: []
    }
  },
  mounted () {
    flatpickr('#datepicker', {})
  },
  computed: {
    formTitle () {
       return (this.editItemId === 0) ? this.$t('crop_price_config.market') + ' ' + this.$t('globalTrans.entry') : this.$t('crop_price_config.market') + ' ' + this.$t('globalTrans.modify')
    },
    detailsTitle () {
       return this.$t('common_config.farmer_info_details')
    },
    orgList: function () {
      const objectData = this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
      return objectData.map((obj, key) => {
          if (this.$i18n.locale === 'bn') {
              return { value: obj.value, text: obj.abbreviation_bn }
          } else {
              return { value: obj.value, text: obj.abbreviation }
          }
      })
    },
    divisionList: function () {
        return this.$store.state.commonObj.divisionList.map(item => {
            if (this.$i18n.locale === 'bn') {
                return { value: item.value, text: item.text_bn }
            } else {
                return { value: item.value, text: item.text_en }
            }
        })
    },
    millInfoList: function () {
      const millInfoList = this.$store.state.incentiveGrant.commonObj.millInfoList.filter(item => item.status === 1)
      return millInfoList.map(item => {
          if (this.$i18n.locale === 'bn') {
              return { value: item.value, text: item.text_bn }
          } else {
              return { value: item.value, text: item.text_en }
          }
      })
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
          { label: this.$t('common_config.farmer_name'), class: 'text-center' },
          { label: this.$t('common_config.farmer_mobile_name'), class: 'text-center' },
          { label: this.$t('common_config.farmer_nid_no'), class: 'text-center' },
          { label: this.$t('globalTrans.address'), class: 'text-center' },
          { label: this.$t('globalTrans.organization'), class: 'text-center' },
          { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center w-15' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'name_bn' },
          { key: 'mobile_no' },
          { key: 'nid_no' },
          { key: 'address' },
          { key: 'org_name_bn' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'name' },
          { key: 'mobile_no' },
          { key: 'nid_no' },
          { key: 'address' },
          { key: 'org_name' },
          { key: 'status' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
    }
  },
  watch: {
    'search.mill_info_id': function (newVal, oldVal) {
      if (oldVal !== newVal) {
        this.subzoneOfficeList = this.getSubZoneList(newVal)
        this.centerList = []
        this.unitList = []
      }
    },
    'search.subzone_office_id': function (newVal, oldVal) {
      if (oldVal !== newVal) {
         this.centerList = this.getCenterList(newVal)
      }
    },
    'search.center_id': function (newVal, oldVal) {
      if (oldVal !== newVal) {
         this.unitList = this.getUnitList(newVal)
      }
    },
    'search.division_id': function (newVal, oldVal) {
      if (oldVal !== newVal) {
         this.districtList = this.getDistrictList(newVal)
      }
    },
    'search.district_id': function (newVal, oldVal) {
      if (oldVal !== newVal) {
         this.upazilaList = this.getUpazillaList(newVal)
      }
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    searchData () {
      this.loadData()
    },
    details (item) {
      this.editItemId = item.id
      this.item = item
    },
    edit (item) {
      this.editItemId = item.id
    },
    remove (item) {
      this.changeStatus(incentiveGrantServiceBaseUrl, farmerInfoToggleStatus, item)
    },
    loadData () {
      this.loader = true
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      RestApi.getData(incentiveGrantServiceBaseUrl, farmerListData, params).then(response => {
        if (response.success) {
            this.$store.dispatch('setList', this.getCustomDataList(response.data.data))
            this.paginationData(response.data)
        } else {
          this.$store.dispatch('setList', [])
        }
        this.loader = false
      })
      this.$store.dispatch('mutateCommonProperties', { loading: false })
    },
    pdfExport () {
        this.$refs.farmerdetails.pdfExport()
    },
    getDistrictList: function (divisionId) {
        const districts = this.$store.state.commonObj.districtList.filter(item => item.division_id === divisionId)
        return districts.map(item => {
            if (this.$i18n.locale === 'bn') {
                return { value: item.value, text: item.text_bn }
            } else {
                return { value: item.value, text: item.text_en }
            }
        })
    },
    getUpazillaList: function (districtId) {
        const upazilas = this.$store.state.commonObj.upazilaList.filter(item => item.district_id === districtId)
        return upazilas.map(item => {
            if (this.$i18n.locale === 'bn') {
                return { value: item.value, text: item.text_bn }
            } else {
                return { value: item.value, text: item.text_en }
            }
        })
    },
    getSubZoneList: function (millInfoId) {
        const subZoneList = this.$store.state.incentiveGrant.commonObj.subZoneList.filter(item => item && item.mill_info_id === millInfoId)
        return subZoneList.map(item => {
            if (this.$i18n.locale === 'bn') {
                return { value: item.value, text: item.text_bn }
            } else {
                return { value: item.value, text: item.text_en }
            }
        })
    },
    getCenterList: function (subZoneId) {
        const centerList = this.$store.state.incentiveGrant.commonObj.centerList.filter(item => item && item.subzone_office_id === subZoneId)
        return centerList.map(item => {
            if (this.$i18n.locale === 'bn') {
                return { value: item.value, text: item.text_bn }
            } else {
                return { value: item.value, text: item.text_en }
            }
        })
    },
    getUnitList: function (centerId) {
        const unitList = this.$store.state.incentiveGrant.commonObj.unitList.filter(item => item.status === 1 && item.center_id === centerId)
        return unitList.map(item => {
            if (this.$i18n.locale === 'bn') {
                return { value: item.unit_no, text: this.$n(item.text_bn) }
            } else {
                return { value: item.unit_no, text: item.text_en }
            }
        })
    },
    getCustomDataList (data) {
        const listData = data.map(item => {
            const millInfoObj = this.$store.state.incentiveGrant.commonObj.millInfoList.find(millTypeList => millTypeList.value === parseInt(item.mill_info_id))
                const millInfoObjData = {}
                    if (typeof millInfoObj !== 'undefined') {
                        millInfoObjData.mill_info = millInfoObj.text_en
                        millInfoObjData.mill_info_bn = millInfoObj.text_bn
                    } else {
                        millInfoObjData.mill_info = ''
                        millInfoObjData.mill_info_bn = ''
                    }

            const SubzoneOfficeObj = this.$store.state.incentiveGrant.commonObj.subzoneOfficeList.find(sobzoneOfficeList => sobzoneOfficeList.value === parseInt(item.subzone_office_id))
                const SubzoneOfficeData = {}
                    if (typeof SubzoneOfficeObj !== 'undefined') {
                        SubzoneOfficeData.subzone_office_name = SubzoneOfficeObj.text_en
                        SubzoneOfficeData.subzone_office_name_bn = SubzoneOfficeObj.text_bn
                    } else {
                        SubzoneOfficeData.subzone_office_name = ''
                        SubzoneOfficeData.subzone_office_name_bn = ''
                    }

            const centerListObj = this.$store.state.incentiveGrant.commonObj.centerList.find(centerList => centerList.value === parseInt(item.center_id))
                const centerListData = {}
                    if (typeof centerListObj !== 'undefined') {
                        centerListData.center = centerListObj.text_en
                        centerListData.center_bn = centerListObj.text_bn
                    } else {
                        centerListData.center = ''
                        centerListData.center_bn = ''
                    }
            const unitListObj = this.$store.state.incentiveGrant.commonObj.unitList.find(unitData => unitData.value === parseInt(item.unit_id))
                const unitListData = {}
                    if (typeof unitListObj !== 'undefined') {
                        unitListData.unit = unitListObj.text_en
                        unitListData.unit_bn = unitListObj.text_bn
                    } else {
                        unitListData.unit = ''
                        unitListData.unit_bn = ''
                    }
            const orgObj = this.$store.state.commonObj.organizationProfileList.find(org => org.value === parseInt(item.org_id))
            const orgData = {}
            if (typeof orgObj !== 'undefined') {
                orgData.org_name = orgObj.text_en
                orgData.org_name_bn = orgObj.text_bn
            } else {
                orgData.org_name = ''
                orgData.org_name_bn = ''
            }
          return Object.assign({}, item, millInfoObjData, SubzoneOfficeData, centerListData, unitListData, orgData)
        })
        return listData
    },
    getAddress (item) {
        const address = []
        if (item.area_type_id === 3) {
            const unionList = this.$store.state.commonObj.unionList
            const tmpUnion = unionList.find(union => union.value === item.union_id)
            if (this.$i18n.locale === 'bn') {
                address.push(tmpUnion ? tmpUnion.text_bn : '')
            } else {
                address.push(tmpUnion ? tmpUnion.text_en : '')
            }
            const upazilaList = this.$store.state.commonObj.upazilaList
            const tmpUpazila = upazilaList.find(upazila => upazila.value === item.upazilla_id)
            if (this.$i18n.locale === 'bn') {
                address.push(tmpUpazila ? tmpUpazila.text_bn : '')
            } else {
                address.push(tmpUpazila ? tmpUpazila.text_en : '')
            }
        } else if (item.area_type_id === 2) {
            const pauroshobaList = this.$store.state.commonObj.pauroshobaList
            const tmpPauroshoba = pauroshobaList.find(pauroshoba => pauroshoba.value === item.pauroshoba_id)
            if (this.$i18n.locale === 'bn') {
                address.push(tmpPauroshoba ? tmpPauroshoba.text_bn : '')
            } else {
                address.push(tmpPauroshoba ? tmpPauroshoba.text_en : '')
            }
        } else {
            const cityCorporationList = this.$store.state.commonObj.cityCorporationList
            const tmpCityCorporation = cityCorporationList.find(ccr => ccr.value === item.city_corporation_id)
            if (this.$i18n.locale === 'bn') {
                address.push(tmpCityCorporation ? tmpCityCorporation.text_bn : '')
            } else {
                address.push(tmpCityCorporation ? tmpCityCorporation.text_en : '')
            }
        }

        const districtList = this.$store.state.commonObj.districtList
        const tmpDistrict = districtList.find(district => district.value === item.district_id)
        if (this.$i18n.locale === 'bn') {
            address.push(tmpDistrict ? tmpDistrict.text_bn : '')
        } else {
            address.push(tmpDistrict ? tmpDistrict.text_en : '')
        }
        const divisionList = this.$store.state.commonObj.divisionList
        const tmpDivision = divisionList.find(division => division.value === item.division_id)
        if (this.$i18n.locale === 'bn') {
            address.push(tmpDivision ? tmpDivision.text_bn : '')
        } else {
            address.push(tmpDivision ? tmpDivision.text_en : '')
        }
        return address.join(', ')
    }
  }
}
</script>
